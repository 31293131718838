<template>
	<div class="header_mto">
		<img class="headerimg" src="@/assets/images/bakedgoods.jpg" />
		<img class="headerimg" src="@/assets/images/bakedgoods2.jpg" />
		<div class="headerfont bakedfont">
			<p>Baked</p>
			<p>Goods</p>
		</div>
	</div>
	<div id="bg_page">
		<h3>Baked goods for sale!</h3>
		<div id="bg_menu">
			<a href="#breads">Breads</a>
			<a href="#pies">Pies</a>
			<a href="#cakes_cupcakes">Cakes &#38; Cupcakes</a>
			<a href="#cookies">Cookies</a>
			<a href="#seasonal">Seasonal</a>
		</div>
		<div class="bg_content">
			<img src="@/assets/images/bread.jpg" alt="bread" class="bakedpic1" />
			<div class="hdr">Breads</div>
			<div id="breads">
				<img src="@/assets/images/bread.jpg" alt="bread2" class="bakedpic2" />
				<div class="bg_item">
					<span>White</span>
					<span>Wheat</span>
					<span>Caraway/Rye</span>
					<span>Porridge</span>
					<span>Cinnamon/Raisin</span>
					<span>Dinner Rolls</span>
				</div>
			</div>
			<div id="sweet_breads">
				<div class="hdr">Sweet Breads</div>
				<div class="bg_item">
					<span>Pumpkin</span>
					<span>Hawaiian</span>
					<span>Lemon/Blueberry (in season)</span>
					<span>Zucchini</span>
					<span>Banana</span>
					<span>Peach</span>
					<span>Peach (in season)</span>
					<span>Rhubarb (in season)</span>
					<span>Apple (in season)</span>
				</div>
				<div id="sticky_buns">
					<div class="hdr">Sticky Buns</div>
					<div class="bg_item">
						<span>Plain</span>
						<span>Nut</span>
						<span>Nut/Raisin</span>
						<span>Lg/md single sizes</span>
						<span>Cinnamon Rolls</span>
						<span>Raspberry Rolls</span>
						<span>Peach Rolls (in season)</span>
						<span>Blueberry Rolls (in season)</span>
					</div>
				</div>
			</div>
			<img src="@/assets/images/pies.jpg" alt="pies" class="bakedpic1" />
			<div class="hdr">Pies</div>
			<div id="pies">
				<div class="bg_item">
					Wetbottom Shoofly
					<span>Funny Cake</span>
					<span>Pecan</span>
					<span>Strawberry</span>
					<span>Rhubarb (in season)</span>
					<span>Raisin</span>
					<span>Apricot</span>
					<span>Milk</span>
					<span>Blueberry</span>
					<span>Mincemeat Pie (in season)</span>
					<span>Apple</span>
					<span>Cherry</span>
					<span>Peach</span>
					<span>Lemon Sponge</span>
					<span>Blueberry Custard</span>
					<span>Raspberry Custard</span>
					<span>Peach Custard</span>
					<span>Cherry Custard</span>
					<span>Rhubarb Custard (in season)</span>
					<span>Pumpkin Pie (in season)</span>
					<span>Corn Pie (in season)</span>
				</div>
				<img src="@/assets/images/pies.jpg" alt="pies" class="bakedpic2" />
			</div>
			<img src="@/assets/images/cupcakes.jpg" alt="cakes & cupcakes" class="bakedpic1" />
			<div class="hdr">Cakes &#38; Cupcakes</div>
			<div id="cakes_cupcakes">
				<img src="@/assets/images/cupcakes.jpg" alt="cakes & cupcakes" class="bakedpic2" />
				<div class="bg_item">
					<span>Chocolate Cakes</span>
					<span>Carrot Cake</span>
					<span>Red Velvet</span>
					<span>Chocolate Zucchini Cake</span>
					<span>Pound Cakes</span>
					<span>Banana Cakes</span>
					<span>Cream Cheese Filled Cupcakes</span>
					<span>Strawberry Cupcakes (in season)</span>
					<span>Chocolate Cupcakes</span>
					<span>Carrot Cupcakes</span>
					<span>Shoo Fly Cupcakes</span>
				</div>
			</div>
			<div id="angel_food">
				<div class="hdr">Angel Food Cakes</div>
				<div class="bg_item">
					<span>Plain</span>
					<span>Confetti</span>
					<span>Chocolate Chip</span>
					<span>Lemon</span>
					<span>Hawaiian</span>
					<span>Black Raspberry</span>
					<span>Peach</span>
					<span>Strawberry Swirl</span>
					<span>Marble</span>
					<span>Black Cherry</span>
				</div>
			</div>
			<img src="@/assets/images/cookies.jpg" alt="cookies" class="bakedpic1" />
			<div class="hdr">Cookies</div>
			<div id="cookies">
				<img src="@/assets/images/cookies2.jpg" alt="cookies" class="bakedpic2" />
				<div class="bg_item">
					<span>Chocolate Chip</span>
					<span>Peanut Butter Oatmeal</span>
					<span>Molasses</span>
					<span>Peanut Butter</span>
					<span>Snicker Doodle</span>
					<span>Oatmeal Raisin</span>
					<span>Peanut Butter Tarts</span>
				</div>
			</div>

			<div id="whoopie_pies">
				<div class="hdr">Whoopie Pies</div>
				<div class="bg_item">
					<span>Chocolate</span>
					<span>Chocolate w/peanut butter</span>
					<span>Chocolate Chip</span>
					<span>Peanut Butter</span>
					<span>Oatmeal</span>
					<span>Red Velvet</span>
					<span>Cranberry/Orange Nut</span>
					<span>Strawberry (in season)</span>
					<span>Blueberry (in season)</span>
					<span>Peach (in season)</span>
				</div>
			</div>
			<div class="baked_column">
				<div id="desserts">
					<div class="hdr">Desserts</div>
					<div class="bg_item">
						<span>Blueberry Trifle</span>
						<span>Peach Trifle</span>
						<span>Raspberry Trifle</span>
						<span>Cream Cheese Peachy Cake</span>
						<span>Pumpkin Torte (in season)</span>
						<span>Rhubarb Custart Bar (in season)</span>
						<span>Apple Torte (in season)</span>
						<span>Pumpkin Rolls (in season)</span>
					</div>
				</div>
				<div id="baked_oatmeal">
					<div class="hdr">Baked Oatmeal</div>
					<div class="bg_item">
						<span>Apple/Cinnamon</span>
						<span>Blueberry</span>
						<span>Peach</span>
						<span>Raisin</span>
						<span>Plain</span>
						<span>Chocolate Chip</span>
						<span>Banana Nut</span>
					</div>
				</div>
			</div>
			<div id="seasonal">
				<img src="@/assets/images/seasonal2.jpg" alt="seasonal items" />
				<div class="hdr">Seasonal Items</div>

				<div id="fasnachts" class="bg_item">
					<span>Fasnachts (January-February)</span>
				</div>
				<div id="eggs" class="bg_item">
					<span>Eggs (March-April)</span>
					<span>Peanut Butter</span>
					<span>Coconut</span>
				</div>
			</div>
			<div id="xmas_cookies">
				<img src="@/assets/images/christmascookies.jpg" alt="christmas cookies" class="bakedpic1" />
				<img src="@/assets/images/christmascookies2.jpg" alt="christmas cookies" class="bakedpic2" />
				<div class="xmas_words">
					<div class="hdr">Christmas Cookies</div>
					<div>(November-December)</div>
					<div class="bg_item">
						<span>Sand Tarts (cut-outs)</span>
						<span>Amish Hats</span>
						<span>Candy Cane Twist</span>
						<span>Chocolate Thumbprints</span>
						<span>Ginger Snaps</span>
						<span>Peanut Butter Blossoms</span>
						<span>Pinwheel Cookies</span>
						<span>Chocolate Pecan Thumbprints</span>
						<span>Baby Cakes</span>
						<span>Peppermint Pinwheels</span>
						<span>Walnut Frosty's</span>
						<span>Pecan Tarts</span>
						<span>Sugar Cookies</span>
					</div>
				</div>
			</div>
			<p>Gluten-free items made by RuthAnn's gluten-free bakery in Lancaster, PA</p>
		</div>
	</div>
</template>
